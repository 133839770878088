
import './App.css';
import Landing from './Landingpage/Landing';
import Practise from './Practise/Practise';

function App() {
  return (
    <div className="">
      <Landing/>
      {/* <Practise/> */}
    </div>
  );
}

export default App;
